import React from "react";

const StartSection = () => {
  return (
    <div id="start" className="bg-white px-2 xl:px-64 lg:px-56  xl:py-14">
      <div className="max-w-[1395px] m-auto ">
        <div className="flex flex-col xl:flex-row justify-between border-dashed xl:border-b-2 xl:border-black xl:border-opacity-20 ">
          <p className="text-lg font-medium py-5 border-b border-black border-dashed border-opacity-20 xl:border-none xl:text-2xl p-5">
            The smart way to build your product...
          </p>
          <p className="text-md py-5 xl:text-lg font-light p-5 xl:w-1/2">
            We combine our Engineering, Marketing and Product expertise into a
            unified development strategy
          </p>
        </div>
        <div className="flex flex-col xl:flex-row items-center gap-y-10 xl:gap-12 justify-between p-5 xl:mt-5">
          <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3 text-2xl mt-10">
            <p className="block w-100 text-md font-light">
              Did you Know{" "}
              <span className="underline decoration-yelo decoration-4">
                95%
              </span>{" "}
              of New Products{" "}
              <span className="underline decoration-yelo decoration-4">
                Fail
              </span>
              ?
            </p>
          </div>
          <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3">
            <img
              src="/img/Rectangle.png"
              className="w-48 h-auto xl:h-64 xl:w-auto"
              alt="Icon 1"
            />
            <p className="block w-100 text-md font-light xl:text-lg">
              That&apos;s why its Important to set the foundations for a
              successful product from the get go {" "}
            </p>
          </div>
          <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3">
            <img
              src="/img/Rectangle2.png"
              className="w-48 h-auto xl:h-64 xl:w-auto"
              alt="Icon 2"
            />
            <p className="block w-100 text-md font-light xl:text-lg">
              You need a plan that puts the customer at the front of the process
            </p>
          </div>
          <div className="flex flex-col justify-center gap-5 items-center xl:w-1/3 mb-10">
            <img
              src="/img/Rectangle3.png"
              className="w-48 h-auto xl:h-64 xl:w-auto"
              alt="Icon 3"
            />
            <p className="block w-100 text-md font-light xl:text-lg">
              And a team that understands all aspects of business
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartSection;
