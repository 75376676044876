import React from "react";
import Link from "@mui/material/Link";

const ThirdSection = () => {
  return (
    <>
      <div className="bg-forest-green xl:px-64 lg:px56 text-white xl:my-0 p-5">
        <div className="max-w-[1395px] m-auto  xl:bg-background-2  xl:bg-80%  xl:py-28   bg-cover 2xl:bg-100% bg-right  bg-no-repeat">
          <div
            id="whyUs"
            className="py-10 xl:pb-10 px-2 flex flex-col  gap-y-6 xl:gap-y-0 xl:w-1/3"
          >
            <p className="text-lg xl:text-2xl xl:mb-12 xl:w-[22.5rem] text-2xl mb-4">
              Let us put{" "}
              <span className="underline decoration-yelo decoration-4">
                your idea
              </span>{" "}
              on the path it{" "}
              <span className="underline decoration-yelo decoration-4">
                deserves
              </span>
            </p>
            <Link
              sx={{
                py: 1.5,
                textAlign: "center",
                cursor: "pointer",
                textDecoration: "none",
              }}
              href="https://calendly.com/asimshaikh/seedstack"
              target="_blank"
              className=" xl:mb-20 shadow-none hover:shadow-none rounded-none bg-bright-green normal-case text-white hover:bg-white hover:border-white hover:text-bright-green xl:self-left font-bold xl:text-lg xl:w-56"
            >
              Ask us more
            </Link>
            <div className="flex justify-center">
              <img
                alt="placeholder"
                className="block pt-12 pb-5 xl:hidden sm:max-w-[500px]"
                src="\img\background-2-mobile.png"
              ></img>
            </div>
            <p className="text-bright-green text-lg font-semi-bold xl:pt-20 xl:border-t-2 border-dashed  xl:text-xl">
              Innovation matters to us.<br></br> That's why...
            </p>
          </div>
          <div className="flex flex-col xl:flex-row flex-wrap gap-10 px-2 pb-20 xl:pb-0">
            <div className="flex flex-row  gap-x-2 xl:w-[360px]">
              <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2 shrink-0">
                <img
                  alt=""
                  src="/img/Ellipse 1.png"
                  className="block !w-full !h-full"
                ></img>
              </div>
              <p className="font-light text-sm xl:text-lg">
                We guide you to create something impactful that has value
              </p>
            </div>
            <div className="flex flex-row  gap-x-2 xl:w-[360px]">
              <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                <img
                  alt=""
                  src="/img/Ellipse 1.png"
                  className="block !w-full !h-full"
                ></img>
              </div>
              <p className="font-light text-sm xl:text-lg">
                We support your visions that will shape the future
              </p>
            </div>

            <div className="flex flex-row  gap-x-2 xl:w-[360px]">
              <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                <img
                  alt=""
                  src="/img/Ellipse 1.png"
                  className="block !w-full !h-full"
                ></img>
              </div>
              <p className="font-light text-sm xl:text-lg">
                We bring you to the forefront of social change to make a
                difference
              </p>
            </div>
            <div className="flex flex-row  gap-x-2 xl:w-[360px]">
              <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                <img
                  alt=""
                  src="/img/Ellipse 1.png"
                  className="block !w-full !h-full"
                ></img>
              </div>
              <p className="font-light text-sm xl:text-lg">
                We take the guesswork out of innovation
              </p>
            </div>
            <div className="flex flex-row  gap-x-2 xl:w-[360px] ">
              <div className="!w-2.5 !h-2.5 !min-w-2.5 mt-2  shrink-0">
                <img
                  alt=""
                  src="/img/Ellipse 1.png"
                  className="block !w-full !h-full"
                ></img>
              </div>
              <p className="font-light text-sm xl:text-lg">
                We help you contribute to a resilient global economy
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdSection;
