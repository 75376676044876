import React from "react";

const SecondSection = () => {
  return (
    <>
      <div className="bg-white pt-10 pb-16 px-2 xl:px-64 lg:px56 text-black ">
        <div
          id="services"
          className="max-w-[1395px] m-auto flex flex-col justify-between gap-y-6 p-5 "
        >
          <div className="xl:w-1/3">
            <p className="text-lg xl:text-2xl">
              Our{" "}
              <span className="underline decoration-yelo decoration-4">
                Services
              </span>
            </p>
          </div>
          <div className="border-b-2 border-dashed xl:mb-2"></div>
          <div className="flex flex-col justify-between gap-y-6 xl:flex-row">
            <div className="flex flex-col justify-between gap-y-6 xl:w-1/4">
              <p className="text-sm font-light xl:text-lg ">Fractional CTO</p>
              <p className="text-sm font-light xl:text-lg ">
                Technical Advisory & Consultation
              </p>
              <p className="text-sm font-light xl:text-lg ">
                Product Engineering
              </p>
              <p className="text-sm font-light xl:text-lg ">
                Go-to-Market Consultation
              </p>
              <p className="text-sm font-light xl:text-lg ">
                Finding Top Talent
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-[1395px] m-auto flex flex-col justify-between gap-y-6 p-5 mt-5 ">
          <div className="xl:w-1/3">
            <p className="text-lg xl:text-2xl">
              What exactly do{" "}
              <span className="underline decoration-yelo decoration-4">
                we do
              </span>
              ?{" "}
            </p>
          </div>
          <div className="border-b-2 border-dashed xl:mb-2"></div>
          <div className="flex flex-col justify-between gap-y-6 xl:flex-row">
            <div className="flex flex-col justify-between gap-y-6 xl:w-1/4">
              <p className="text-sm font-light xl:text-lg ">
                Identify & resolve uncertainties fast
              </p>
              <p className="text-sm font-light xl:text-lg">
                Enhance your credibility with investors & stakeholders
              </p>
            </div>
            <div className="flex flex-col justify-between gap-y-6  xl:w-1/4">
              <p className="text-sm font-light xl:text-lg">
                Help you make better decisions
              </p>
              <p className="text-sm font-light xl:text-lg">
                Align with customer & market expectations 
              </p>
            </div>
            <div className="flex flex-col justify-between gap-y-6  xl:w-1/4">
              <p className="text-sm font-light xl:text-lg">
                Optimise your costs & resources
              </p>
              <p className="text-sm font-light xl:text-lg">
                Provide a solid foundation for sustainable growth
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSection;
